<template>
  <div class="vr-store">
    <div class="iframe">
      <iframe src="/tom_greyhound_21fw.html" title="" />
    </div>
    <div class="close">
      <img src="../assets/icon_xbutton_bk.png" alt="close" @click="goBack()" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.vr-store {
  height: 100%;
  .iframe {
    width: 100vw;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: #f5f5f5;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      overflow: hidden;
    }
  }
  .close {
    position: fixed;
    top: 15px;
    right: 20px;
    img {
      width: 44px;
    }
  }
}
</style>
